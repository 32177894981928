import Css from "./style.module.scss";

import { getActiveOrganization } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Chart from "react-apexcharts";
import Constants from "const/Constants";
import React, { useMemo } from "react";
import Utils from "utils/Utils";
import moment from "moment";

const { NEGATIVE, HIGHLIGHT, POSITIVE, WHITE } = Constants.COLORS;

const GRADIENT_FROM_OPACITY = 0.75;

const GRADIENT_TO_OPACITY = 0.95;

const LINE_WIDTH = 4;

const MARKER_SIZE = 6;

const COLUMN_MIN_WIDTH = 50;

const COLUMN_MAX_WIDTH = 90;

const COLUMN_WIDTH_STEPS = 12;

const AXIS_Y_TICK_AMOUNT = 10;

const LEGEND_Y_OFFSET = 6;

const CashFlowChart = ({ data }) => {
  const { PERCENTS_MULTIPLIER, DATETIME_FORMATS: { MONTH_AND_YEAR_TEXT } } = Constants;

  const activeOrganization = useSelector(getActiveOrganization);

  const { uiTexts } = useSelector(getTextsData);

  const originalChartData = useMemo(() => {
    return [...data].reverse();
  }, [data]);

  const { months, incomeValues, outcomeValues, netProfitValues } = useMemo(() => {
    const result = originalChartData.reduce((aggregator, { month, income, outcome, netProfit }) => {
      aggregator.months.push(month);
      aggregator.incomeValues.push(income);
      aggregator.outcomeValues.push(outcome);
      aggregator.netProfitValues.push(Utils.toMoneyNumber(income / PERCENTS_MULTIPLIER * netProfit));

      return aggregator;
    }, { months: [], incomeValues: [], outcomeValues: [], netProfitValues: [] });

    if (!result.incomeValues.some(Boolean)) result.incomeValues = [];
    if (!result.outcomeValues.some(Boolean)) result.outcomeValues = [];
    if (result.netProfitValues.length <= 1 || !result.netProfitValues.some(Boolean)) result.netProfitValues = [];

    return result;
  }, [PERCENTS_MULTIPLIER, originalChartData]);

  const columnWidth = useMemo(() => {
    const stepsCount = months.length > COLUMN_WIDTH_STEPS ? COLUMN_WIDTH_STEPS : months.length;

    const stepSize = (COLUMN_MAX_WIDTH - COLUMN_MIN_WIDTH) / COLUMN_WIDTH_STEPS;

    return `${COLUMN_MIN_WIDTH - stepSize + (stepSize * stepsCount)}%`;
  }, [months]);

  const maxScaleValue = useMemo(() => {
    return Math.max(...[...incomeValues, ...outcomeValues, ...netProfitValues]);
  }, [incomeValues, outcomeValues, netProfitValues]);

  const series = useMemo(() => [
    {
      type: "area",
      data: netProfitValues
    },
    {
      name: "income",
      type: "column",
      data: incomeValues
    },
    {
      name: "outcome",
      type: "column",
      data: outcomeValues
    },
    {
      name: "netProfit",
      type: "line",
      data: netProfitValues
    }
  ], [incomeValues, outcomeValues, netProfitValues]);

  const options = useMemo(() => ({
    chart: {
      toolbar: { show: false },
      zoom: { enabled: false },
      animations: { enabled: false }
    },
    colors: [HIGHLIGHT, POSITIVE, NEGATIVE, HIGHLIGHT],
    stroke: {
      curve: "smooth",
      width: [0, 0, 0, LINE_WIDTH]
    },
    markers: {
      size: [0, 0, 0, MARKER_SIZE],
      strokeWidth: LINE_WIDTH,
      colors: WHITE,
      strokeColors: HIGHLIGHT,
      hover: { sizeOffset: LINE_WIDTH >> 1 }
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: 0,
        stops: [0, PERCENTS_MULTIPLIER],
        opacityFrom: [GRADIENT_FROM_OPACITY, GRADIENT_FROM_OPACITY, GRADIENT_FROM_OPACITY, 1],
        opacityTo: [0, GRADIENT_TO_OPACITY, GRADIENT_TO_OPACITY, 1]
      }
    },
    states: {
      active: {
        filter: {
          type: "none",
          value: 0
        }
      }
    },
    plotOptions: { bar: { columnWidth, borderRadius: LINE_WIDTH } },
    tooltip: {
      shared: true,
      followCursor: true,
      theme: "dark",
      enabledOnSeries: series.map((value, index) => index || null),
      x: { formatter: (value) => moment.utc(value).format(MONTH_AND_YEAR_TEXT) },
      y: {
        title: { formatter: (value) => `${uiTexts[value]}:` },
        formatter: (value, { seriesIndex, dataPointIndex }) => {
          const percents = seriesIndex ? "" : ` (${originalChartData[dataPointIndex].netProfit})%`;

          return value && (Utils.toMoneyString(value, activeOrganization.currency) + percents);
        }
      }
    },
    legend: {
      showForZeroSeries: false,
      showForNullSeries: false,
      horizontalAlign: "center",
      offsetY: LEGEND_Y_OFFSET,
      onItemClick: { toggleDataSeries: false },
      formatter: (value) => uiTexts[value] || ""
    },
    xaxis: {
      axisBorder: { show: false },
      tooltip: { enabled: false },
      tickPlacement: "between",
      categories: months,
      labels: { formatter: (value) => moment.utc(value).format(MONTH_AND_YEAR_TEXT) }
    },
    yaxis: {
      axisTicks: { show: false },
      axisBorder: { show: false },
      forceNiceScale: true,
      title: { text: `${uiTexts.total} (${activeOrganization.currency})` },
      labels: { formatter: (value) => value && Math.floor(value) },
      tickAmount: AXIS_Y_TICK_AMOUNT,
      min: 0,
      max: maxScaleValue
    }
  }), [
    PERCENTS_MULTIPLIER,
    MONTH_AND_YEAR_TEXT,
    uiTexts,
    activeOrganization,
    originalChartData,
    months,
    series,
    columnWidth,
    maxScaleValue
  ]);

  return (
    <div className={Css.cashFlowChart}>
      <Chart
        height="100%"
        options={options}
        series={series} />
    </div>
  );
};

export default React.memo(CashFlowChart);
