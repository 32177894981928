import Css from "./style.module.scss";

import { FiCalendar } from "react-icons/fi";
import React from "react";
import classNames from "classnames";

const DashboardWidget = ({
  title,
  date,
  className,
  highlight,
  alignedText,
  iconComponent: Icon,
  customTitleRightComponent: CustomTitleRightContent,
  children
}) => {
  const classNamesList = [
    Css.dashboardWidget,
    className,
    highlight && Css.highlight,
    alignedText && Css.alignedText
  ];

  return (
    <div className={classNames(classNamesList)}>
      <div className={Css.titleContainer}>
        <div className={Css.title}>
          <Icon />
          <span>{title}</span>
        </div>
        {CustomTitleRightContent
          ? <CustomTitleRightContent />
          : date && <div className={Css.date}>
            <span>{date}</span>
            <FiCalendar />
          </div>}
      </div>
      <div className={Css.content}>{children}</div>
    </div>
  );
};

export default React.memo(DashboardWidget);
