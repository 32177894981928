import CommonCss from "lib/common/style.module.scss";

import Css from "./style.module.scss";

import { Card, Page, Preloader } from "lib/common";
import { CardBody, Col, Row } from "shards-react";
import {
  FiActivity,
  FiArrowDown,
  FiArrowLeft,
  FiArrowRight,
  FiArrowUp,
  FiBarChart2 as FiBarChart,
  FiCheckCircle,
  FiChevronsUp,
  FiFileText,
  FiRefreshCw,
  FiTrendingUp
} from "react-icons/fi";
import { Link, withRouter } from "react-router-dom";
import { bind } from "decko";
import { checkDashboardFetching, getDashboardData } from "selectors/dashboard";
import { connect } from "react-redux";
import { getActiveOrganization } from "selectors/organizations";
import { getEnvVars } from "selectors/envVars";
import { getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import CashFlowChart from "./lib/CashFlowChart";
import Constants from "const/Constants";
import DashboardActions from "actions/DashboardActions";
import DashboardFilter from "./lib/DashboardFilter";
import DashboardWidget from "./lib/DashboardWidget";
import DataConstants from "const/DataConstants";
import MediaQuery from "react-responsive";
import PageActionsRow from "lib/common/PageActionsRow";
import PageHeader from "lib/common/PageHeader";
import React, { PureComponent } from "react";
import TurnoverTrendsChart from "./lib/TurnoverTrendsChart";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import moment from "moment";

const mapStateToProps = (state) => ({
  envVars: getEnvVars(state),
  fetchingData: checkDashboardFetching(state),
  selectedBusinessId: getSelectedBusinessId(state),
  activeOrganization: getActiveOrganization(state),
  dashboardData: getDashboardData(state),
  textsData: getTextsData(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchDashboardData: (...args) => dispatch(DashboardActions.fetchDashboardData(...args))
});

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
class DashboardPage extends PureComponent {
  static AGGREGATION_TYPES = {
    DOCUMENTS: "documents",
    TRANSACTIONS: "transactions"
  };

  constructor(props) {
    super(props);

    const { envVars: { fromDate, toDate } } = this.props;

    const validDateRange = moment(fromDate).isValid() && moment(toDate).isValid();

    this.state = {
      filters: {
        fromDate: validDateRange ? fromDate : undefined,
        toDate: validDateRange ? toDate : undefined
      }
    };
  }

  componentDidMount() {
    const { fetchDashboardData } = this.props;

    fetchDashboardData(DashboardPage.AGGREGATION_TYPES.DOCUMENTS).then((dashboardData) => {
      if (dashboardData && dashboardData.cashFlow.byMonth.length) {
        const { filters: { fromDate, toDate } } = this.state;

        if (!fromDate && !toDate) {
          let [{ month }] = dashboardData.cashFlow.byMonth;

          month = moment.utc(month);
          this.setState({
            filters: {
              fromDate: Utils.formatApiDate(month.startOf("year"), true),
              toDate: Utils.formatApiDate(month.endOf("year"), true)
            }
          });
        }
      }
    });
  }

  @bind
  handleDashboardFilterChange(filters) {
    this.setState({ filters }, () => {
      this.props.history.replace(`?${Utils.objectToQueryString(filters)}`);
    });
  }

  renderNoDataContent() {
    const {
      fetchingData,
      textsData: { uiTexts }
    } = this.props;

    if (fetchingData) return <Preloader />;

    return (
      <div className={CommonCss.noDataContent}>
        <div>
          <div><FiActivity /></div>
          <div>{uiTexts.noData}</div>
        </div>
      </div>
    );
  }

  renderTurnoverWidget(cashFlowData) {
    const { DATETIME_FORMATS: { MONTH_AND_YEAR_TEXT }, PERCENTS_MULTIPLIER } = Constants;

    const { activeOrganization: { currency }, textsData: { uiTexts } } = this.props;

    const [lastMonthData] = cashFlowData;

    const preLastMonth = Utils.formatApiDate(moment.utc(lastMonthData.month).subtract(1, "months"), true);

    const preLastMonthData = cashFlowData.find(({ month }) => month === preLastMonth);

    const renderTrendsBlock = () => {
      if (preLastMonthData) {
        const turnoverDifference = lastMonthData.turnover - preLastMonthData.turnover;

        const turnoverDifferencePercent = PERCENTS_MULTIPLIER * (
          preLastMonthData.turnover
            ? Math.abs(turnoverDifference / preLastMonthData.turnover)
            : (lastMonthData.turnover ? 1 : 0)
        );

        return (
          <div className={Css.trends}>
            <TurnoverTrendsChart value={turnoverDifference} />
            <div>
              <div>{uiTexts.sincePreviouseMonth}</div>
              <div>
                {turnoverDifference > 0 ? <FiArrowUp /> : (turnoverDifference < 0 ? <FiArrowDown /> : null)}
                <span>{`${Utils.toMoneyString(turnoverDifference, currency)} `}</span>
                <b>{`(${Utils.toMoneyNumber(turnoverDifferencePercent)}%)`}</b>
              </div>
            </div>
          </div>
        );
      }

      return null;
    };

    return (
      <DashboardWidget
        highlight
        title={uiTexts.turnover}
        date={moment.utc(lastMonthData.month).format(MONTH_AND_YEAR_TEXT)}
        iconComponent={FiTrendingUp}
        className={Css.turnoverWidget}>
        <div className={Css.turnover} data-single={preLastMonthData ? undefined : ""}>
          {Utils.toMoneyString(lastMonthData.turnover, currency)}
        </div>
        {renderTrendsBlock()}
      </DashboardWidget>
    );
  }

  renderDocumentsWidget(cashFlowData, documents) {
    const { API_DATE, MONTH_AND_YEAR_TEXT } = Constants.DATETIME_FORMATS;

    const { BUY, SELL } = DataConstants.DOCUMENT_PAYMENT_TYPES;

    const { textsData: { uiTexts }, selectedBusinessId } = this.props;

    const [lastMonthData] = cashFlowData;

    const documentsCount = documents.byMonth
      .find(({ month }) => month === lastMonthData.month) || { buy: 0, sell: 0, toReport: 0 };

    const dateFilter = {
      fromDate: moment.utc(lastMonthData.month).startOf("month").format(API_DATE),
      toDate: moment.utc(lastMonthData.month).endOf("month").format(API_DATE)
    };

    const buyQuery = Utils.objectToQueryString({ ...dateFilter, paymentType: BUY });

    const sellQuery = Utils.objectToQueryString({ ...dateFilter, paymentType: SELL });

    const toReportQuery = Utils.objectToQueryString({ ...dateFilter, status: DataConstants.STATUSES.TO_REPORT });

    return (
      <DashboardWidget
        alignedText
        title={uiTexts.documents}
        date={moment.utc(lastMonthData.month).format(MONTH_AND_YEAR_TEXT)}
        iconComponent={FiFileText}>
        <div>
          <span><FiArrowRight /><span>{`${uiTexts.issuedInvoices}: `}</span></span>
          <span>
            <Link to={`/${selectedBusinessId + UiRoutes.DOCUMENTS}/?${sellQuery}`}>
              <b>
                <span>{documentsCount.sell}</span>
                <FiFileText />
              </b>
            </Link>
          </span>
        </div>
        <div>
          <span><FiArrowLeft /><span>{`${uiTexts.billsToPay}: `}</span></span>
          <span>
            <Link to={`/${selectedBusinessId + UiRoutes.DOCUMENTS}/?${buyQuery}`}>
              <b>
                <span>{documentsCount.buy}</span>
                <FiFileText />
              </b>
            </Link></span>
        </div>
        <div>
          <span><FiCheckCircle /><span>{`${uiTexts.approved}: `}</span></span>
          <span>
            <Link to={`/${selectedBusinessId + UiRoutes.DOCUMENTS}/?${toReportQuery}`}>
              <b>
                <span>{documentsCount.toReport}</span>
                <FiFileText />
              </b>
            </Link></span>
        </div>
      </DashboardWidget>
    );
  }

  renderTotalTurnoverWidget({ total: { netProfit, income, outcome } }, currentYear) {
    const { PERCENTS_MULTIPLIER } = Constants;

    const { activeOrganization: { currency }, textsData: { uiTexts } } = this.props;

    return (
      <DashboardWidget alignedText title={uiTexts.totalTurnover} date={currentYear} iconComponent={FiRefreshCw}>
        <div>
          <span><FiChevronsUp className={CommonCss.highlightText} /><span>{`${`${uiTexts.netProfit}:`}`}</span></span>
          <span className={CommonCss.highlightText}>
            <span>{Utils.toMoneyString(income / PERCENTS_MULTIPLIER * netProfit, currency)} </span>
            <b>{`(${netProfit}%)`}</b>
          </span>
        </div>
        <div>
          <span><FiArrowUp className={CommonCss.positiveText} /><span>{`${`${uiTexts.income}:`}`}</span></span>
          <span className={CommonCss.positiveText}>{Utils.toMoneyString(income, currency)}</span>
        </div>
        <div>
          <span><FiArrowDown className={CommonCss.negativeText} /><span>{`${`${uiTexts.outcome}:`}`}</span></span>
          <span className={CommonCss.negativeText}>{Utils.toMoneyString(outcome, currency)}</span>
        </div>
      </DashboardWidget>
    );
  }

  renderCashFlowChartWidget(data, period) {
    const { textsData: { uiTexts } } = this.props;

    return (
      <DashboardWidget
        title={`${uiTexts.cashFlowChart} (${uiTexts.documents.toLowerCase()})`}
        date={period}
        iconComponent={FiBarChart}>
        <CashFlowChart data={data} />
      </DashboardWidget>
    );
  }

  render() {
    const { DATETIME_FORMATS: { MONTH_TEXT, MONTH_AND_YEAR_TEXT } } = Constants;

    const {
      dashboardData: { cashFlow, documents },
      fetchingData
    } = this.props;

    const { filters } = this.state;

    const cashFlowData = cashFlow && cashFlow.byMonth ? cashFlow.byMonth : [];

    const filteredCashFlowData = cashFlowData.length && filters.fromDate && filters.toDate
      ? cashFlow.byMonth.filter(({ month }) => {
        month = moment.utc(month);

        return month.isSameOrAfter(moment.utc(filters.fromDate)) && month.isSameOrBefore(moment.utc(filters.toDate));
      })
      : cashFlowData;

    let filteredPeriodText = "";

    if (filteredCashFlowData.length) {
      const endDate = moment.utc(filteredCashFlowData[0].month);

      const startDate = moment.utc(filteredCashFlowData[filteredCashFlowData.length - 1].month);

      if (startDate.isSame(endDate)) filteredPeriodText = endDate.format(MONTH_AND_YEAR_TEXT);
      else {
        const startDateText = startDate.year() === endDate.year()
          ? startDate.format(MONTH_TEXT)
          : startDate.format(MONTH_AND_YEAR_TEXT);

        filteredPeriodText = `${startDateText} - ${endDate.format(MONTH_AND_YEAR_TEXT)}`;
      }
    }

    return (
      <Page className={Css.dashboardPage}>
        <PageHeader />
        <Card>
          <CardBody>
            <PageActionsRow sticky={Object.values(filters).filter(Boolean).length}>
              <DashboardFilter
                key={[filters.fromDate, filters.toDate].toString()}
                uniqueYears={cashFlowData.length ? cashFlow.uniqueYears : []}
                minDate={cashFlowData.length && moment.utc(cashFlowData[cashFlowData.length - 1].month).startOf("year").toDate()}
                maxDate={cashFlowData.length && moment.utc(cashFlow.byMonth[0].month).endOf("year").toDate()}
                initialValue={filters}
                disabled={fetchingData || !cashFlowData.length}
                onChange={this.handleDashboardFilterChange} />
            </PageActionsRow>
            <div className={Css.contentWrapper}>
              <Row>
                {filteredCashFlowData.length
                  ? <MediaQuery maxWidth={Constants.SIMPLIFIED_LAYOUT_MAX_WIDTH}>
                    {(matches) => matches
                      ? <Col className={Css.simplifiedLayoutColumn}>
                        <Row>
                          <Col className={Css.widgetsColumn}>{this.renderTurnoverWidget(filteredCashFlowData)}</Col>
                          <Col className={Css.widgetsColumn}>{this.renderDocumentsWidget(filteredCashFlowData, documents)}</Col>
                        </Row>
                        <Row>
                          <Col className={Css.chartColumn}>
                            {this.renderCashFlowChartWidget(filteredCashFlowData, filteredPeriodText)}
                          </Col>
                        </Row>
                        <Row>
                          <Col className={Css.widgetsColumn}>{this.renderTotalTurnoverWidget(cashFlow, filteredPeriodText)}</Col>
                        </Row>
                      </Col>
                      : <>
                        <Col className={Css.widgetsColumn}>
                          <Row><Col>{this.renderTurnoverWidget(filteredCashFlowData)}</Col></Row>
                          <Row><Col>{this.renderDocumentsWidget(filteredCashFlowData, documents)}</Col></Row>
                          <Row><Col>{this.renderTotalTurnoverWidget(cashFlow, filteredPeriodText)}</Col></Row>
                        </Col>
                        <Col className={Css.chartColumn}>
                          {this.renderCashFlowChartWidget(filteredCashFlowData, filteredPeriodText)}
                        </Col>
                      </>}
                  </MediaQuery>
                  : <Col className={Css.noDataColumn}>
                    {this.renderNoDataContent()}
                  </Col>}
              </Row>
            </div>
          </CardBody>
        </Card>
      </Page>
    );
  }
}

export default DashboardPage;
