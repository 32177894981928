import Chart from "react-apexcharts";
import Constants from "const/Constants";
import React, { useMemo } from "react";

const { NEGATIVE, WARNING, POSITIVE } = Constants.COLORS;

const GRADIENT_FROM_OPACITY = 0.75;

const LINE_WIDTH = 4;

const SCALE_LIMITS_OFFSET = 0.1;

const TurnoverTrendsChart = ({ value = 0 }) => {
  const options = useMemo(() => ({
    chart: { sparkline: { enabled: true } },
    colors: [value > 0 ? POSITIVE : (value === 0 ? WARNING : NEGATIVE)],
    stroke: {
      curve: "smooth",
      width: LINE_WIDTH
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: GRADIENT_FROM_OPACITY,
        opacityTo: 0
      }
    },
    tooltip: { enabled: false },
    yaxis: {
      min: -SCALE_LIMITS_OFFSET,
      max: 1 + SCALE_LIMITS_OFFSET
    }
  }), [value]);

  const series = useMemo(() => [
    { data: [value <= 0 ? 1 : 0, value >= 0 ? 1 : 0] }
  ], [value]);

  return (
    <div>
      <Chart
        type="area"
        key={value}
        options={options}
        series={series} />
    </div>
  );
};

export default React.memo(TurnoverTrendsChart);

