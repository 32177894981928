import Css from "./style.module.scss";

import { Button } from "shards-react";
import { Form } from "lib/common";
import { bind } from "decko";
import { connect } from "react-redux";
import { getTextsData } from "selectors/texts";
import DateTimeRangePicker from "lib/common/DateTimeRangePicker";
import React, { PureComponent } from "react";
import Utils from "utils/Utils";
import moment from "moment";

const MAX_RECENT_UNIQUE_YEARS = 5;

const mapStateToProps = (state) => ({
  textsData: getTextsData(state)
});

@connect(mapStateToProps, null)
class DashboardFilter extends PureComponent {
  constructor(props) {
    super(props);

    const { initialValue: { fromDate, toDate } } = this.props;

    this.state = {
      dateRangeEnabled: !!(fromDate && toDate),
      fromDate: fromDate || "",
      toDate: toDate || ""
    };
  }

  emitChangeEvent() {
    const { dateRangeEnabled, fromDate, toDate } = this.state;

    const filters = {};

    if (dateRangeEnabled) filters.fromDate = fromDate;
    if (dateRangeEnabled) filters.toDate = toDate;
    this.props.onChange(filters);
  }

  @bind
  handleFromDateInputChange(value) {
    const { toDate } = this.state;

    const formattedValue = value ? Utils.formatApiDate(value, true) : "";

    this.setState({
      fromDate: formattedValue,
      toDate: (!value || !toDate || moment.utc(toDate).isBefore(moment.utc(value))) ? formattedValue : toDate,
      dateRangeEnabled: toDate
    }, () => {
      if (toDate) this.emitChangeEvent();
    });
  }

  @bind
  handleToDateInputChange(value) {
    const { fromDate } = this.state;

    const formattedValue = value ? Utils.formatApiDate(value, true) : "";

    this.setState({
      toDate: formattedValue,
      fromDate: (!value || !fromDate || moment.utc(value).isBefore(moment.utc(fromDate))) ? formattedValue : fromDate,
      dateRangeEnabled: fromDate
    }, () => fromDate && this.emitChangeEvent());
  }

  @bind
  handleToggleDateRangeButtonClick() {
    this.setState(
      ({ dateRangeEnabled, fromDate, toDate }) => ({
        dateRangeEnabled: !dateRangeEnabled,
        fromDate: dateRangeEnabled ? "" : fromDate,
        toDate: dateRangeEnabled ? "" : toDate
      }),
      () => this.emitChangeEvent()
    );
  }

  @bind
  handleYearFilterButtonClick({ currentTarget: { dataset: { year } } }) {
    const { minDate, maxDate } = this.props;

    const startOfYearDate = moment.utc(year).startOf("year").toDate();

    const endOfYearDate = moment.utc(year).endOf("year").toDate();

    const fromDate = Utils.formatApiDate(minDate && startOfYearDate > minDate ? startOfYearDate : minDate);

    const toDate = Utils.formatApiDate(maxDate && endOfYearDate < maxDate ? endOfYearDate : maxDate);

    this.setState(
      {
        dateRangeEnabled: true,
        fromDate,
        toDate
      },
      () => {
        this.emitChangeEvent();
      }
    );
  }

  render() {
    let { uniqueYears, minDate, maxDate, disabled } = this.props;

    const { fromDate, toDate } = this.state;

    uniqueYears = uniqueYears.slice(0, MAX_RECENT_UNIQUE_YEARS);

    return (
      <div className={Css.dashboardFilter}>
        <Form>
          <DateTimeRangePicker
            showMonthYearPicker
            disabled={disabled}
            minDate={minDate}
            maxDate={maxDate}
            startDate={fromDate}
            endDate={toDate}
            fromDate={fromDate}
            toDate={toDate}
            onFromDateChange={this.handleFromDateInputChange}
            onToDateChange={this.handleToDateInputChange}
            onToggleClick={this.handleToggleDateRangeButtonClick} />
          {uniqueYears.length > 1 && uniqueYears.map((value) => {
            return (
              <Button
                key={value}
                theme="secondary"
                disabled={disabled}
                data-year={value}
                onClick={this.handleYearFilterButtonClick}>
                {value}
              </Button>
            );
          })}
        </Form>
      </div>
    );
  }
}

export default DashboardFilter;
